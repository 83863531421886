
.general {
    padding:0px;
    flex: none;
    overflow: hidden;
    // margin-bottom: 12px;
    width:300px;
    &::-webkit-scrollbar {
    width: 5px;
    }
    &::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
    background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }
    .messagesList {
        height: 70vh;
        /* width: 72px; */
        display: flex;
        flex-direction: column;
        flex: none;
        overflow: scroll;
        overflow-x: hidden;
        ul {
            padding: 0px;
            .loadMore {
                background-color:#4e92bf;
                color: #fff;
                padding:12px;
                text-align: center;
                justify-content: center;
                &:hover {
                background-color:#67a5ce;
                }
            }
            li {
                padding: 8px 15px;
                display: flex;
                align-items: center;
                transition:.2s all;
                background-color:transparent;
                cursor: pointer;
                &.active {
                    background-color: #E6EAE3
                }
                &:hover{
                    background-color:#f1f1f1
                }
                .status {
                    position: absolute;
                    bottom: 2px;
                    right: -4px;
                    margin-right: 4px;
                    width: 14px;
                    height: 14px;
                    border:2px solid #fff;
                    border-radius: 100%;
                    z-index: 2;
                    &.online {
                        background-color: #3ec243
                    }
                    &.offline {
                        background-color: #f56667
                    }
                }
                .avatarContent {
                    margin-right: 10px;
                    position: relative;
                    .avatar{
                        width:45px;
                        height:45px;
                        display:flex;
                        align-items:flex-start;
                        justify-content:center;
                        position:relative;
                        border-radius:100%;
                        overflow:hidden;
                        margin: auto;
                        background-position: center;
                        background-size: cover;
                    }
                }
                .messageIcon {
                background: #e899ff;
                height: 64px;
                display: flex;
                align-items: center;
                color: #fff;
                cursor:pointer
                }

                .nickname{
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 500;
                    font-size: 15px;
                    margin:0px;
                    justify-content: flex-start;
                    display: flex;
                    justify-content: space-between;
                    small {
                        // margin-left: auto;
                        font-size: 13px;
                        opacity: .5;
                    }
                }
                h2{
                margin: 0px 0 6px 0;
                font-size: 22px;
                color: #65676b;
                }
                .text {
                    padding-top:2px; 
                    height:48px; 
                    overflow:hidden; 
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    flex:1;
                    .messageSmall{
                        margin:0px;
                        color:#a0a0a0
                    }
                }
                span {
                display: flex;
                position: absolute;
                z-index: 2;
                width: 16px;
                height: 16px;
                background: #e899ff;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                font-size: 8px;
                color: #f0f2f5;
                font-weight: bold;
                right: -4px;
                top: -4px;
                border: 1px solid #f0f2f5;
                }
            }
        }
    }
}

.general {
    &.messagesPage {
        flex: none;
        height:calc(100vh - 184px);
        overflow-y: auto;
        margin: 0;
    }
}

@media (min-width:991px){

    .general {
        .messagesList {
            height: calc(100vh - 184px);
        }
    }
}
@media (max-width:991px){
    .general {
        width:100%;
        // left: 100%!important;
        // opacity: 1!important;
        // display: flex!important;
        // flex-direction: column;
        // margin-top: 0;
        // height: auto;
        // width: 100%;
        // z-index: 990;
        // top: 0px;
        // bottom: 0;
        // right: 0;
        // position: fixed;
        // background: #fff;
        // padding: 20px;
        // margin-bottom: 0px;
        // overflow: auto;
        // -webkit-transition: all .3s;
        // transition: all .3s;
        &.active {
            left: 0!important;
        }
        .messagesList {
            height:100%
        }
        .title {
            display: flex;
            justify-content:space-between;
            align-items:center;
            margin-bottom: 15px;
            i {
                display: inline;
                font-size: 20px;
            }
        }
    }
}