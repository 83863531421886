
.general {
    padding:10px;
    flex: none;
    overflow: scroll;
    // margin-bottom: 12px;
    width:100%;
    &::-webkit-scrollbar {
    width: 5px;
    }
    &::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
    background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }

    .messagesList {
        /* width: 72px; */
        display: flex;
        flex-direction: column;
        flex: none;
        ul {
            padding: 0px;
            .loadMore {
                background-color:#4e92bf;
                color: #fff;
                padding:12px;
                text-align: center;
                justify-content: center;
                &:hover {
                background-color:#67a5ce;
                }
            }
            li {
                padding: 0px;
                display: flex;
                align-items: center;
                transition:.2s all;
                background-color:transparent;
                cursor: pointer;
                border-radius: 30px;
                margin-bottom: 14px;
                position: relative;
                b {
                    max-width: 60px;
                    overflow: hidden;
                }
                &.active {
                    background-color: #E6EAE3
                }
                &.disable {
                    background-color: #E6EAE3;
                    opacity: .5;
                    pointer-events: none;
                }
                &:hover{
                    background-color:#f1f1f1;
                    .close{
                        opacity: 1;
                        pointer-events: all;
                    }
                }
                .content{

                    display: flex;
                    align-items: center;
                    width: 100%;
                }
                .close{
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    line-height: 20px;
                    background-color: rgb(231, 76, 60);
                    color: rgb(255, 255, 255);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    opacity: 0;
                    pointer-events: none;
                    transition: .2s all;
                }
                .status {
                    position: absolute;
                    bottom: 2px;
                    right: -4px;
                    margin-right: 4px;
                    width: 14px;
                    height: 14px;
                    border:2px solid #fff;
                    border-radius: 100%;
                    z-index: 2;
                    &.online {
                        background-color: #3ec243
                    }
                    &.offline {
                        background-color: #f56667
                    }
                }
                .avatarContent {
                    position: relative;
                    .avatar{
                        width:35px;
                        height:35px;
                        display:flex;
                        align-items:flex-start;
                        justify-content:center;
                        position:relative;
                        border-radius:100%;
                        overflow:hidden;
                        margin: auto;
                        background-position: center;
                        background-size: cover;
                    }
                }
                .messageIcon {
                background: #e899ff;
                height: 64px;
                display: flex;
                align-items: center;
                color: #fff;
                cursor:pointer
                }

                .nickname{
                    white-space: nowrap;
                    font-weight: 500;
                    font-size: 15px;
                    margin:0px;
                    justify-content: flex-start;
                    display: flex;
                    justify-content: space-between;
                    padding:0 10px;
                    small {
                        // margin-left: auto;
                        font-size: 13px;
                        opacity: .5;
                    }
                }
                h2{
                margin: 0px 0 6px 0;
                font-size: 22px;
                color: #65676b;
                }
                .text {
                    // padding-top:2px; 
                    overflow:hidden; 
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    flex:1;
                    .messageSmall{
                        margin:0px 10px;
                        color:#a0a0a0
                    }
                    .cc {
                        display: flex;
                        flex-wrap: wrap;
                        span {
                            display: flex;
                        }
                        b {
                            margin:0px 10px;
                            font-size: 12px;
                        }
                    }
                }
                // span {
                // display: flex;
                // position: absolute;
                // z-index: 2;
                // width: 16px;
                // height: 16px;
                // background: #e899ff;
                // align-items: center;
                // justify-content: center;
                // border-radius: 100%;
                // font-size: 8px;
                // color: #f0f2f5;
                // font-weight: bold;
                // right: -4px;
                // top: -4px;
                // border: 1px solid #f0f2f5;
                // }
            }
        }
    }
}

.general {
    &.messagesPage {
        flex: none;
        height:calc(100vh - 146px);
        overflow-y: auto;
        margin: 0;
    }
}

@media (min-width:991px){

    .general {
        .messagesList {
            height: calc(100vh - 146px);
        }
    }
}
@media (max-width:991px){
    .general {
        width:100%;
        height: 100%;
        // left: 100%!important;
        // opacity: 1!important;
        // display: flex!important;
        // flex-direction: column;
        // margin-top: 0;
        // height: auto;
        // width: 100%;
        // z-index: 990;
        // top: 0px;
        // bottom: 0;
        // right: 0;
        // position: fixed;
        // background: #fff;
        // padding: 20px;
        // margin-bottom: 0px;
        // overflow: auto;
        // -webkit-transition: all .3s;
        // transition: all .3s;
        &.active {
            left: 0!important;
        }
        .messagesList {
            height:100%
        }
        .title {
            display: flex;
            justify-content:space-between;
            align-items:center;
            margin-bottom: 15px;
            i {
                display: inline;
                font-size: 20px;
            }
        }
    }
}