.Gifts{
    display:flex;
    flex-wrap: wrap;
    .box {
        width: 25%;
        padding:5px;
        .content {
            display:flex;
            flex-direction: column;
            align-items: center;
            padding:10px;
            border:2px solid #fff;
            border-radius: 5px;
            transition: .2s all;
            cursor: pointer;
            &:hover {
                border-color: #783BF9;
            }
        }
        img{
            height:70px;
        }
        p {
            margin-bottom: 5px;
            margin-top: 0px;
        }
        button {
            background-color: #21D4FD !important;
            background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%) !important;
            width:100px;
            height:22px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 16px;
            border-radius: 5px;
            margin-top: 5px;
        }
    }
}
@media(max-width:991px){
    .Gifts{
        .box {
            width: 50%;
        }
    }
}