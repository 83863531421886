.box{
    width:400px;
    flex:none;
    overflow:hidden;
    display:flex;
    height: 100vh;
    flex-direction:column;
    .close {
        width: 37px;
        height: 37px;
        background-color: red;
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:20px;
        display: none;
    }
}
.ul {
  padding:7px;
    li {
        font-size: 12px;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
        &:last-child{
        margin-bottom: 0px;
        }
    }
}
.ul_2 {
    padding:7px;
    height:100%;
    li {
        display: flex;
        justify-content: space-between;
        p {
            margin:0px;
            font-size: 11px;
            white-space: nowrap;
            font-weight: 600;
            line-height: 30px;
        }
        input {
            background-color: transparent;
            border:0px;
            height: 28px;
            text-align: right;
            margin-bottom: 5px;
        }
    }
}

.ul_3 {
    li {
        input {
            height: 28px;
        }
        input, textarea {
            background-color: rgba(0, 0, 0, 0.05);
            width: 100%;
            margin-bottom: 5px;
            line-height: 28px;
            text-align: left;
            padding: 0 15px;
            border:0px
        }
    }
}
.note_1 {    
  max-height: 108px;
  overflow: auto;
  margin-bottom: 16px;
    li {
        background: #6A728C;
        border-radius: 5px;
        color: #fff;
        font-size: 11px;
        padding: 0 6px 6px;
        display: flex;
        margin-bottom: 8px;
        line-height: 11px;
        p {
            margin:0
        }
        small {
            color: #fff;
            opacity: .5;
            float: right;
        }
        div {
            flex:1
        }
    }
}
@media(max-width:991px){
    .box{
        width: 100%;
        display: none;
        position: fixed;
        top:0;
        bottom:0;
        right:0;
        left:0;
        z-index: 999;
        .close {
            display: flex;
        }
    }
}