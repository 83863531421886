
.messageBox_2{
  position: relative;
  width: 100%;
  transition: .3s all;
  background: #f1f1f1;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex:1;
  height: 100vh;
  padding:10px;
  .buttons {
      margin-bottom: 10px;
      display: flex;
  }
  .oldImages {
      display: flex;
      flex-wrap: wrap;
      li {
          width: 50%;
          img {
              width: 100%;
          }
      }
  }
  .info {
      height: 32px; 
      padding-left: 14px; 
      padding-right: 14px; 
      background: red; 
      color: rgb(255, 255, 255); 
      line-height: 32px; 
      border-radius: 5px;
      margin-bottom: 10px;
  }
    .online {
        color:#6FBA78
    }
    .ccPhoto{
        display:flex;
        flex-wrap: wrap;
        width: 300px;

        p{
            width: 100%; 
            text-align: center;
        }
        li {
            width: 81px; 
            margin: 5px;
            height: 81px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow:hidden;
            cursor: pointer;
            img {
                max-height:none;
                overflow: hidden;
                width: 100%!important;
            }
        }
    }
.note_1 {    
    max-height: 108px;
    overflow: auto;
    margin-bottom: 0px;
    width: 100%;
    li {
        background: #6A728C;
        border-radius: 3px;
        color: #fff;
        font-size: 12px;
        padding: 6px;
        display: flex;
        margin-bottom: 8px;
        width: 100%;
        p {
        margin:0;
        color: #fff;
        }
        small {
        color: #fff;
        opacity: .5;
        float: right;
        }
        div {
        flex:1
        }
    }
}
  .writing {
    position: absolute;
    top: -25px;
    right: 22px;
    font-size: 12px;
    background: #fff;
    padding: 3px 6px;
    border-radius: 3px;
    opacity: 0.7;
    color: #000;
    display: flex;
    align-items: center;
    span {
        position: relative;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: #000;
        color: #000;
        animation: dotFlashing 1s infinite linear alternate;
        animation-delay: 0s;
        animation-delay: .5s;
        display: block;
        margin:3px 6px 0px  10px;
        &:before, &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        }

        &:before {
        left: -6px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: #000;
        color: #000;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
        }

        &:after {
        left: 6px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: #000;
        color: #000;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
        }
    }
  }

    @keyframes dotFlashing {
    0% {
        background-color: #000;
    }
    50%,
    100% {
        background-color: #ebe6ff;
    }
    }

//   height: 70vh;
    .avatar {
        width:60px;
        height:60px;
        border-radius:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        position:relative;
        overflow:hidden;
        background-position: center;
        background-size: cover;
        flex:none;
        &:hover .overlay {
            opacity: 1;
        }
        .overlay {
            background:rgba(0,0,0,0.3);
            display:flex;
            align-items:center;
            justify-content:center;
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            font-size:16px;
            opacity: 0;
            transition: .3s all;
            color:#fff;
        }
    }
    .nickname {
        flex:1;
        padding:0 10px;
        font-weight: 500;
        justify-content: flex-start;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        a{
            float:left;
            margin-bottom: 5px;
            &:hover{
            text-decoration: underline;
            }
        }
        .status {
            font-weight: 300;
            font-size: 13px;
            display: flex;
            align-items: center;
            &.online  {
                color: #3ec243
            }
            &.offline  {
                color: #f56667
            }
        }
    }
    .close {
        margin-left: 5px;
        color: #bec2c9;
        cursor: pointer;
        font-size: 20px;
        margin-right: 5px;
    }
    .messageContent {
        // padding:10px;
        flex: 1;
        // overflow: hidden;
        overflow:auto;
        overflow-x:hidden;
        ul {
            border-top: 1px solid #ddd;
            padding: 10px 0px;
            // margin-bottom: 20px;
            display: flex;
            flex-direction: column-reverse;
            li {
                color: #000;
                clear: both;
                line-height: 18px;
                font-size: 15px;
                padding: 8px;
                position: relative;
                margin: 8px 0;
                max-width: 85%;
                word-wrap: break-word;
                // z-index: -1;
                p {
                    margin: 0;
                }
            }
        }
        .messageLeft {
            background: #fff;
            border-radius: 0px 5px 5px 5px;
            margin-right: auto;
            &>div {
                // width:80%;
                // text-align:left;
                // display: flex;
                .avatar{
                    width: 28px;
                    height: 28px;
                    margin-right: 5px;
                    float: left;
                }
            }
            &:after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0px 10px 10px 0;
                border-color: transparent #fff transparent transparent;
                top: 0;
                left: -10px;
            }
            .time img {
                display: none;
            }
        }
        .messageRight {
            // flex-direction:row-reverse;
            background: #e1ffc7;
            border-radius: 5px 0px 5px 5px;
            // float: right;

            margin-left: auto;
            // &>div {
            //     width:80%;
            //     text-align:right;
            //     margin-left: auto;
            // }
            &:after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0px 0 10px 10px;
                border-color: transparent transparent transparent #e1ffc7;
                top: 0;
                right: -10px;
            }
        }
        .buyBtn{
            display:none
        }
        .buyBtnActive {
            /* display:block; */
            /* position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0; */
            margin:auto;
            padding:0px 20px;
            background:#e899ff;
            color:#fff;
            font-weight:bold;
            width: 115px;
            height: 50px;
            border-radius: 5px;
            line-height: 18px;
            font-size: 12px;
            cursor:pointer
        }
        .blur {
            /* filter: blur(10px); */
            /* min-width:200px; */
            min-height:150px;
            /* background-color:#ddd; */
            /* pointer-events: none; */
            display:flex;
            flex-direction:center;
            justify-content:center
        }
        .time {
            display: inline-block;
            float: right;
            padding: 0 0 0 7px;
            position: relative;
            bottom: -4px;
            color: rgba(0, 0, 0, .45);
            font-size: 12px;
            // font-size: 10px;
            // // margin-left: 20px;
            // margin-top: 3px;
            // width: 100%;
            // display: block;
            // position: absolute;
            // left: 0px;
            // bottom: -21px;
            // white-space: nowrap;
            img {
                display: inline-block;
                margin-left: 2px;
                position: relative;
                top: 4px;
                height: 16px;
                width: 16px;
            }
        }
        .get {
            // padding: 7px 14px;
            // font-size: 15px;
            // background: #4e92bf;
            // color: #fff;
            // // border-radius: 22px;
            // margin: 0;
            // display: inline-block;
            // position: relative;
            // line-height: 20px;
            // margin-bottom: 15px;
            // p {
            //     margin:10px 0;
            // }
        }
        p {
        word-break: break-word;
        }
        .post {
            // padding: 7px 14px;
            // font-size: 14px;
            // background: #3f4257;
            // color: #fff;
            // // border-radius: 22px;
            // margin:0;
            // display: inline-block;
            // position:relative;
            // line-height: 20px;
            // margin-bottom: 15px;
            // .time {
            //     left: auto;
            //     right: 0px;
            //     text-align: right;
            // }
            
        }
    }
    .sendingImageGeneral {
        // position: absolute;
        // top: 187px;
        left: 0px;
        height: 50px;
        border-radius: 5px;
        /* overflow: hidden; */
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
        opacity: .9;
        border: 2px solid #fff;
        position: relative;
        margin-bottom: 10px;
        .deleteMsg {
            position: absolute;
            right: -6px;
            top: -6px;
            width: 20px;
            height: 20px;
            line-height: 19px;
            text-align: center;
            display: flex;
            justify-content: center;
            background: #d81616;
            border-radius: 100%;
            font-size: 10px;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .imageCoin {
        input {
            height:50px;

            width:100px;
            border-radius: 5px;
            margin-left:5px;
            border:1px solid #ddd;
            padding:0 10px;
        }
    }
    .footer {
        display:flex;
        align-items:center;
        padding: 0px;
        flex-direction:column;
        position:relative;

        margin-bottom: 10px;
        .coin {
            font-size: 13px;
            margin-bottom: 5px;
            padding: 1px 6px;
        }
        .messageGeneral {
            display: flex;
            align-items: center;
            flex-direction: column;
            width:100%;
            &>div {
                display: flex;
                align-items: center;
                width:100%;
                position: relative;
            }
        }
        .inputContent {
            display:flex;
            flex:1;
            border-radius: 3px;
            border: 1px solid #ddd;
            background: #fff;
            position:relative;
            overflow: hidden;
            width:100%;
            padding: 8px;

            flex-direction: column;
            // padding: 8px 0;
            justify-content: center;
            i {
                color: #ada4ff;
                font-size:20px;
                z-index: 2;
            }
        }
        .icons {
            display: flex;
            margin-top: 5px;
            .icon {
                width: 36px;
                height: 36px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                color: #333;
                font-size:16px;
                margin-left: 5px;
                position: relative;
                cursor: pointer;
                border: 1px solid #ddd;
                overflow: hidden;
                &:hover {
                    background-color: #f1f1f1;
                }
                img {
                    width:24px;
                }
                input {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    cursor:pointer;
                    z-index:2;
                    opacity:0
                }
            }
        }
        .checkbox {
            // margin-left: auto;
            cursor: pointer;
            label {
                display: flex;
                align-items: center;
                font-size: 13px;
            }
        }
    }
    .messageInput {
    border:0;
    padding:0px 10px;
    outline: none;
    font-size:16px;
    background: transparent;
    line-height: 20px;
    width: 100%;
    }
    .button {
        height: 42px;
        width: 42px;
        border: 0;
        color: #ada4ff;
        padding: 0;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        outline:none;
        cursor:pointer;
        background: transparent;
        i {
        font-size:22px;
        width: auto;
        }
    }
    .gifModal {
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        height: 300px;
        overflow: auto;
        font-size: 24px;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 40px;
        right: 20px;
        left: 20px;
        z-index: 2;
        .load {
            width: 100%;
            text-align: center;
            opacity: .7;
            margin: 10px 0;
            display: block;
            font-size: 17px;
            background: #eee;
            padding: 5px 0;
            cursor: pointer;
        }
        ul {
            column-count: 3;
            column-gap: 10px;
            li {
                display: grid;
                grid-template-rows: 1fr auto;
                margin-bottom: 10px;
                break-inside: avoid;
                img {
                    max-width: 100%;
                    display: block;
                    max-height: 100%;
                    grid-row: 1 / -1;
                    grid-column: 1;     
                }
            }
        }
        .input {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 10px;
            img {
                width:24px;
                position: absolute;
                right:10px;
                cursor: pointer;
                cursor: pointer;

            }
        }
    }
    .gifBg {
        position: fixed;
        top:0;
        bottom:0;
        right:0;
        left:0;
        background-color:rgba(0,0,0,0.2);
        z-index:1;
    }
    .emoji {
        width: 300px;
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        height: 300px;
        overflow: auto;
        font-size: 24px;
        box-shadow: 0 0 9px rgba(0,0,0,0.1);
        left: 0;
        top: 40px;
        bottom: none;
        position: absolute;
        z-index: 2;
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                width: 40px;
                text-align: center;
                line-height: 40px;
                height: 40px;
                cursor: pointer;
                border-radius: 5px;
                background-color: transparent;
                transition: .2s all;
                &:hover {
                    background-color: #eee;
                }
            }
        }
    }
    .emojiBg {
        position: fixed;
        top:0;
        bottom:0;
        right:0;
        left:0;
        background-color:rgba(0,0,0,0.2);
        z-index:1;
        display: none;
    }
    .userBtn, .fakeBtn {
        display: none;
    }
}

// .messageBox_2{
//     &.messagesPage {
//         position: relative;
//         right: 0;
//         bottom: 0;
//         width: 100%;
//         flex: 1;
//         height: calc(100vh - 60px);
//         border-radius: 0px;
//         margin:0;
//         box-shadow:  0 0 0;
//         z-index: 0;
//     }
// }
@media (max-width:991px){
    .general {
        width:100%
    }
    .messageBox_2 {
        min-height: 100%;
        // background-color: #fff;
        // height: 1000px;
        // background-color: #fff;
        .messageTextarea {
            font-size: 16px;
        }
        .checkbox {
            display: none;
        }
        .icons {
            flex-direction: row-reverse;
            .icon {
                margin-right: 5px;
                margin-left: 0px!important;
            }
        }
        .userBtn, .fakeBtn {
            position: absolute;
            bottom:15px;
            left:15px;
            display: block;
            background-color: #333;
            color: #fff;
            border-radius: 5px;
            padding:8px 12px;
            z-index: 999;
        }
        .fakeBtn{
            left: auto;
            right: 15px;
            background-color:var(--primary-color);
        }
    }
    // .messageBox_2{
    //     position: fixed!important;
    //     right: 0!important;
    //     // bottom: 0!important;
    //     top:0!important;
    //     width: 100%!important;
    //     flex: 1!important;
    //     height:auto!important;
    //     border-radius: 0px!important;
    //     margin:0!important;
    //     box-shadow:  0 0 0!important;
    //     z-index: 999!important;
    // }
}