

// @keyframes top {
//  0% {
//   transform: translateY(-30px);
//   opacity: 0;
//  }
 
//  100% {
//   opacity: 1;
//   transform: none;
//  }
// }


@keyframes right {
 0% {
  transform: translateX(-30px);
  opacity: 0;
 }
 
 100% {
  opacity: 1;
  transform: none;
 }
}

@keyframes left {
 0% {
  transform: translateX(30px);
  opacity: 0;
 }
 
 100% {
  opacity: 1;
  transform: none;
 }
}

.landingPage_1 {
    min-height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    overflow: hidden;
    // -webkit-animation: top 1s .4s both;
    // animation: top 1s .4s both;
    .left{
        -webkit-animation: right 1s .4s both;
        animation: right 1s .4s both;
    }
    .right{
        -webkit-animation: left 1s .4s both;
        animation: left 1s .4s both;
    }
    .left, .right {
        height: 100vh;
        flex: 1;
        display:flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
    }
    .test {
        display: flex;
        justify-content: space-between;
        img {
            height: 100px;
            border-radius: 3px;
            margin-right:6px;
            box-shadow: 0 0 5px rgba(0,0,0,0.1);
        }
    }
    .textColor {
      color: #9997a2
    }
    .a {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration:none
      }
    }
    .formTopText {
      color: #9997a2;
      padding: 5px 0px;
      font-size: 15px;
      text-align:center;
      margin-bottom: 10px;
      display: block;
    }
    .slider_button {
        z-index:100;
        text-align: center;
    }
    .slick-track {
        display:flex
      }
    .left {
        flex-direction: column;
        justify-content: space-between;
        padding:20px
    }
    .bottom {
        width: 100%;
    }
    .count {
        text-align: left;
        color: #fff;
        font-size: 36px;
        font-weight: 300;
        opacity: .8;
        p {
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 30px;
        }
    }
    .right{
        overflow: auto;
    }
    .login {
        width: 400px;
        margin-bottom: 0px;
        .close {
            display: block;
            margin: auto;
            font-size: 35px;
        }
    }
    .text {
        max-width: 90%;
        padding:0 30px;
        color:#fff;
        p {
            font-size: 14px;
            margin-bottom: 20px;
            font-weight: 500;
            text-decoration: underline;
        }
        h1 {
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 10px;
            line-height: 44px;
        }
        button {
            max-width: 360px;
            margin-left: auto;
            margin-right: auto;
            &:nth-child(4){
                margin-top: 20px!important;
            }
        }
    }
    .genderRadio .MuiFormGroup-root {
        flex-wrap: nowrap;
    }
    .genderRadio .MuiFormControlLabel-root>span:nth-child(1){
        padding: 10px 6px;
    }
    .boxHeader {
        padding: 25px 0;
        font-weight: 500;
        text-align: center;
        p {
            margin: 0;
            display: initial;
            background: #fff;
            z-index: 2;
            position: relative;
            padding: 0 10px;
        }
        hr {
            margin-top: -13px;
        }
    }
    .box {
        background-color: #fff;
        border: 0;
        display:flex;
        border-radius:5;
        border-color:#e6edf6;
        overflow: hidden;
        margin:0
    }
    .box2 {
        flex:1
    }
    .blue {
        color: #3394cc;
        &:hover {
            text-decoration: underline;
        }
    }
    .logos {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: calc(50% + 18px);
        padding: 15px;
        justify-content: flex-end;
        img {
            height:41px;
            display: block;
        }
    }
    
    .social {
        display: flex;
        margin-right: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 15px;
        justify-content: flex-end;
        img {
            height:41px;
            display: block;
        }
        a {
            color: #4267B2;
            margin-right: 18px;
            border-radius: 5px;
            width: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            border: 1px solid #e6edf6;
            margin-left: auto;
            &:nth-child(2) {
                color: #833AB4;
            }
        }
    }
    .menus {
        display:flex;
        justify-content: space-between;
        color:#fff;
        width:100%;
        font-size: 14px;
        font-weight: 500;
        ul {
            display:flex;
            li {
                margin:0 8px;
                cursor: pointer;
            }
        }
    }
    .loginBtn {
        position: absolute;
        top:15px;
        right:20px;
        padding:10px 15px;
        border-radius: 40px;
        box-shadow: 0 0 0;
    }

    .MuiFormControl-marginNormal {
        margin-top: 10px;
    }
    .navbar {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 2;
        left:0;
        right:0;
        top:15px;
        ul {
            float:right;
            display: flex;
            align-items: center;
            white-space: nowrap;
            li {
                a {
                    padding: 0 15px;
                    height: 54px;
                    font-weight: 700;
                    font-size: 14px;
                    position: relative;
                    color: #fff;
                    vertical-align: middle;
                    border: 2px solid;
                    border-color: transparent;
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                    &.active {
                        color: #e899ff;
                        border-color:#e899ff
                    }
                }
                &:last-child{
                    margin-right: 20px
                }
            }
        }
        .logo {
            color: #3f4257;
            display: flex;
            align-items: center;
            .img_wrap {
                margin-right: 13px;
                transition: opacity .3s ease;
                max-width: 250px;
                img{
                    max-height: 54px;
                }
            }
            .sub-title {
                font-size: 10px;
                text-transform: uppercase;
                margin-top: 2px;
                opacity: .5;
            }
        }
    }
}
@media (max-width:991px){
    .landingPage_1 {
        .right {
            min-height: 100vh;
            // height: auto;
            // position: fixed;
            // bottom:0;
            // top:0;
        }
        .test {
            justify-content: center;
            margin-bottom: auto;
            img{
                height: 80px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
        .buttonMobile, .buttonMobile2 {
            height:50px;
        }
        .buttonMobile {
            display:block;
            margin-top:25px!important;
            margin: auto;
        }
        .buttonMobile2 {
            display:block;
            margin-top:25px!important;
            margin: auto;
        }
        .text h1 {
            font-size:30px
        }
        .logos {
            position:relative;
            top:0;
            left: 0;
            right: 0;
            justify-content: center;
        }
        .menus ul {
            flex-wrap: wrap;

            justify-content: flex-end;
        }
        .left {
            width:100%;
            min-height: 100vh;
            height: 100%;
            display: none;
        }
        .right {
            // position: fixed;
            // left:100%;
            // top:0;
            // bottom:0;
            // right:0;
            transition: .3s all;
            background-color: #fff;
            padding:20px;
            &.active {
                left:0;
                align-items: baseline;
            }
        }
        .login, .box {
            min-height:100vh
        }
        .box {
            padding:20px
        }
        .closeMobile {
            display: block;
            margin: auto;
            font-size:35px
        }
        .text {
            padding:0px;
        }
        .MuiButton-sizeSmall {
            display:none!important
        }
        .slick-arrow {
            display:none!important
        }
        #step3>button:nth-child(1){
            display:none
        }
        #step3 .boxHeader{
            display:none
        }
        .logos {
            justify-content: center;
            padding:0px
        }
        .social {
            width: 100px;
            margin: 30px auto 10px auto;
            display: none;
            justify-content: space-between;
            a {
                background-color: #ffffff;
                width: 44px;
                flex:none;
                margin:0px!important
            }
        }
        .count {
            text-align: center;
            display: flex;
            align-items:center;
            p {
                margin-bottom: 0px;
                margin-left: 15px;
                text-decoration: none;
            }
        }
    }
}
@media (max-width:991px){
    body .landingPage_1 {
        // position: fixed;
        // left: 0;
        // top: 0;
        // bottom: 0;
        // right: 0;
        // height: 100%;
        min-height: 100%;
        &>div {
            left:0;
            right:0;
        }
    }
}

@media(max-width:500px){
    .landingPage_1 .text h1 {
        font-size: 20px;
        line-height: 26px;
    }
}