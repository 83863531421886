$primary-color: var(--primary-color);
$primary-color-dark: var(--primary-color-dark);
$secondary-color: #6d98f9;
$secondary-color-dark: #5084fb;
$secondary2-color: #aca3ff;
$secondary2-color-dark: #8578ff;
$dark-color: #454952;
$dark-color-dark: #33363e;

.DatePicker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.3);
  }
}
.buttons {
  box-shadow: 0px 0px 0px;
  padding: 0px 15px;
  border-radius: 50px;
  height:52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-transform: uppercase;
  transition:.2s all;
  width: 100%;
  &:disabled,
  &[disabled]{
    opacity: .5;
    cursor: auto;
  }

  &.primaryOutline {
    color: $primary-color;
    border: 2px solid $primary-color;
    background-color: #fff;
    &:hover {
      background-color: $primary-color;
      color: #fff
    }
  }
  &.message {
    margin: 10px 0 0px 0;
    height: 44px;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0px;
    color: #fff;
    border-radius: 0px;
    font-size: 13px;
    font-weight: 600;
    border-top: 1px solid rgba(255,255,255,0.32);
    // background-color: #54bedf;
    position: absolute;
    bottom:0;
    left: 0;
    right: 0;
    cursor: pointer;
    button{
      background-color:transparent;
      color: #fff;
      font-size: 13px;
      height: 45px;
      width:100%;
      font-weight: 600;
      text-transform: uppercase;
    }
    span {
      flex: 1;
      display: flex;
      justify-content:center;
      align-items: center;
    }
    i{
      border-radius: 0 5px 5px 0;
      background: #42a3c1;
      height: 100%;
      padding: 0 8px;
      margin-left: auto;
      font-size: 22px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active{
        background: #f1f2f5!important;
        color: #f3d227
      }
    }
    &:hover {
      background-color: $primary-color;
      color: #fff
    }
  }
  &.danger {
    color: #fff;
    background-color: #f56667;
    &:hover {
      background-color: #f13e3f;
      color: #fff
    }
  }
  &.blue {
    color: #fff;
    background-color: #3498da;
    &:hover {
      background-color: #5fa9da;
      color: #fff
    }
  }
  &.orange {
    background-color: #f39c12;
    color: #fff;
    &:hover {
      background-color: #e67e22;
    }
  }
  &.danger_2 {
    color: #fff;
    background-color: #dc3545;
    &:hover {
      background-color: #bd2130;
      color: #fff
    }
  }
  &.primary {
    color: #fff;
    background-color: $primary-color;
    &:hover {
      background-color: $primary-color-dark;
      color: #fff
    }
  }
  &.secondary {
    color: #fff;
    background-color: $secondary-color;
    &:hover {
      background-color: $secondary-color-dark;
      color: #fff
    }
  }
  &.secondary2 {
    color: #fff;
    background-color: $secondary2-color;
    &:hover {
      background-color: $secondary2-color-dark;
      color: #fff
    }
  }
  &.gold {
    color: #fff;
    background-color: #f6c358;
    &:hover {
      background-color: #e8a34e;
      color: #fff
    }
  }
  &.dark {
    color: #fff;
    background-color: $dark-color;
    &:hover {
      background-color: $dark-color-dark;
      color: #fff
    }
  }
  &.success {
    padding: 12px 15px;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    background-color: var(--success-color);
    &:hover {
      background-color: var(--success-color-dark);
    }
  }
}

.messageInput {
  padding:8px 0;
  padding-right: 24px;
  padding-left: 10px;
  flex: 1;
  &.type_3 {
    padding:4px 8px;
    width:100%;
  }
    textarea {
      resize: none;
      overflow: hidden;
      min-height: 20px;
      line-height:20px;
      max-height: 100px;
      border: 0px;
      background-color: transparent;
      outline: none;
      width: 100%;
      &:focus{
        box-shadow:  0 0 0;

      }
    }
}

.modal {
  position: fixed;
  top:0;
  right:0;
  left:0;
  bottom:0;
  z-index: 999;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition:.3s all;
  max-width: 100%!important;
  &.buyGift {
    .title {
      background-color: #4158D0 !important;
      background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%) !important;
      color: #fff;
    }
  }
  &.active {
  opacity: 1;
  pointer-events: auto;
    .bg {
      opacity: 1;
    }
    .content {
      // animation: modalAnimation2 500ms ease-out forwards;
      margin: 140px auto;
      opacity: 1;
    }
  }
  .bg {
    position: fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background-color: rgba(0,0,0,0.5);
    transition:.3s all;
    opacity: 0;
    // -webkit-backdrop-filter: blur(5px);
    // backdrop-filter: blur(5px);
  }
  .content{
    flex: none;
    margin: 150px auto;
    background-color:#fff;
    border-radius: 3px;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    transition:.5s all;
    opacity: 0;
    .title {
      padding:15px 20px;
      border-bottom: 1px solid #eee;
      background: #fcfcfc;
      border-radius: 10px 10px 0 0;
      h3 {
        font-size: 25px;
        font-weight: 500;
        margin:0px;
      }
    }
    .close {
      position: absolute;
      top: -12px;
      right: -12px;
      width: 40px;
      height: 40px;
      background: #e74c3c;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      cursor: pointer;
      transition:.2s all;
      z-index: 1;
      img {
        width: 18px;
      }
      &:hover {
        background: #c0392b;
      }
    }
    .padding {
      padding:20px;
    }
  }
  .footer {
    &>div {
      padding:15px;
      display:flex;
      justify-content: center;
      flex-wrap: wrap;
      button:nth-child(2){
        margin-left: 15px;
      }
    }
  }
}

.match_alert {
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  .avatars {
      display: flex;
      justify-content: center;
      align-items: center;
      &>div {
      margin: 40px 10px;
      }
      .avatar {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      border: 3px solid #ada4ff;
      background-size: cover;
      background-position: center;
      }
  }
}


@media (min-width:991px){

  .modal {
    animation: modalAnimation 400ms ease-out forwards;
    &.active {
      opacity: 0;
      pointer-events: auto;
      .content {
        animation: modalAnimation2 300ms ease-out forwards;
      }
    }
  }
}
@media (max-width:991px){
  .modal {
    .content{
      max-width: 90%;
    }

    &.active {
      .content {
        margin: 20px auto;
      }
    }
    &.messageModal {
      .content {
        margin:0 auto!important;
        max-width:100%;
        border-radius: 0px;
        overflow: hidden;
        height:100%;
        .close {
          top:2px;
          right: 1px;
          box-shadow: 0 0 0;
        }
        // height: 100vh;
      }
      .padding>div:nth-child(1)>div:nth-child(1){
        border-radius: 0px!important;
      }
    }
  }
  .messageInput {
    textarea {
      font-size: 16px;
    }
  }
  .buttons{
    &.message{
      margin-top: 0px;
    }
  }
}
@keyframes modalAnimation {
  to {
    opacity: 1;
  }
}
@keyframes modalAnimation2 {
  to {
    margin: 60px auto;
  }
}

