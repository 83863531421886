
.headerBar{
    width: 100%;
    display: flex;
    justify-content: center;
    padding:12px;
    background-color: #3f4257;
    color: #fff;
    border-radius: 0 0 3px 3px;
    font-size: 16px;
    height: 54px;
    .container {
        width: 1300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .stat {
            display: flex;
            select{
                background-color: rgba(255,255,255,0.1);
                color: #fff;
                width:90px;
                border:0px;
                height: 36px;
                border-radius: 3px 0 0 3px;
                padding:0 5px;
            }
            ul{
                display: flex;
                border: 1px solid rgba(255,255,255,0.1);
                border-radius: 0 3px 3px 0;
                line-height: 33px;
                li {
                    padding: 0 14px;
                    border-right: 1px solid rgba(255,255,255,0.1);
                    span {
                        opacity: .7;
                    }
                    b {
                        margin-left: 5px;
                    }
                    &:last-child {
                        border-right: 0px;
                    }
                }
            }
            .reload {
                background: #525667;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0 3px 3px 0;
                cursor: pointer;
                img {
                    width: 20px;
                }
            }
        }
        .headerProfile {
            .profile {
                display: flex;
                align-items: center;
                font-weight: bold;
                cursor: pointer;
                img {
                    width:12px;
                    margin-left: 6px;
                }
                
            }
            ul {
                li{
                    span{
                        color: #3f4257;
                    }
                }
            }
        }
    }
}

@media(max-width:991px){

    .headerBar{
        .container {
            width: 100%;  
            // overflow-x: auto;
            .stat {
                margin-right: 10px;
                select {
                    width: 66px;
                }
                ul{
                    line-height: 18px;
                    li {
                        padding: 0 4px;
                        display: flex;
                        flex-direction: column;
                        span {
                            font-size:12px;
                        }
                        b {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}