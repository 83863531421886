.Dashboard {
    width: 100%;
    margin-top: 10px;
    display: flex;
    border: 1px solid #eee;
    border-bottom: 0px;
    border-radius: 3px;
    .list {
        width: 100%;
        flex: 1 1;
        border-right: 1px solid #eee;
        &:first-child{
        }
        &:last-child{
            border-right: 0px;

        }
    }
    .title {
        font-size: 16px;
        margin-bottom: 0px;
        display: block;
        background-color: #eee;
        padding:10px;
        text-align: center;
    }
    .message {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
    }
}
@media (max-width:991px){
    .Dashboard{
        display: flex;
        flex-direction: column;
        .list{
            height: auto;
        }
    }
}