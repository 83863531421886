$primary-color: #e899ff;
$primary-color-dark: #ac79bb;
$white: #fff;

.general {
    display: flex;
    flex-direction: column;
    color: #4d4d4d;
    .left {
        flex:none;
        width: 270px;
        padding:20px 0;
        margin-right: 30px;
    }
    .content {
        flex:1;
        width: 1300px;

    }
}
.mobileLayout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    .content {
        flex:1;
    }
}
@media(max-width:991px){
    .Layout{
        overflow-x: auto;
        width: 100%;
        .content{
            width: 100%;
        }
    }
}