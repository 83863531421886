.formGroup{ 
    width: 100%;
    height: 52px;
    background-color: #fff;
    border:1px solid #eee;
    border-radius: 3px;
    display: block;
    // margin-top: 8px;
    margin-bottom: 12px;
    position: relative;
    i {
        color: #9a9a9a;
        position: absolute;
        top:0;
        bottom:0;
        margin:auto;
        left: 10px;
        height: 26px;
        font-size: 18px;
        pointer-events: none;
    }
    label {
        color: #9a9a9a;
        position: absolute;
        top:12px;
        margin:auto;
        left: 10px;
        height: 22px;
        font-size: 16px;
        transition: .2s all;
        pointer-events: none;
    }
    select{
        padding-left: 6px!important;
        border:none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
        & + label {
            top:5px;
            font-size: 13px;
            opacity: .7;
        }
    }
    input, select, textarea {
        transition: .2s all;
        width: calc(100% + 2px);
        height: 52px;
        padding-top: 20px;
        border: 1px solid transparent;
        position: absolute;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-radius: 3px;
        font-size: 16px;
        padding-left: 10px;
        background-color: transparent;
        top: -1px;
        &:focus {
            border:1px solid #0984e3
        }
        &:focus + label, &:valid + label {
            top:5px;
            font-size: 13px;
            opacity: .7;
        }
    }
    &.textarea{
        height: 120px;
        textarea {
            height: 120px;
        }
    }
    &.type_radio{
        display: flex;
        background-color: #f1f1f1;
        .bg {
            position: absolute;
            top:-1px;
            left:calc(50% + 1px);
            width: 50%;
            right: 0;
            bottom: -1px;
            background-color: #74b9ff;
            transition: .4s all;
            border-radius: 3px;
            border-radius: 0 3px 3px 0;
            &.activeBg {
                background-color: #ff9ff3;
                left: -1px;
                border-radius: 3px 0 0 3px;
            }
        }
        div{
            flex:1;
            position: relative;
            cursor: pointer;
            z-index: 2;
            input {
                opacity: 0;
                cursor: pointer;
                &:checked + label {
                    color: #fff;
                    // background-color: #f368e0;
                    // border-radius: 3px 0 0 3px;
                }
            }
            &:nth-child(2){
                input {
                    &:checked + label {
                        color: #fff;
                        // background-color: #3498db;
                        // border-radius: 0 3px 3px 0px;
                    }
                }
            }
            label{
                top:0;
                left: 0;
                opacity: 1;
                height: auto;
                bottom: 0;
                margin: auto;
                width: 100%;
                font-size: 16px;
                color: #222;
                background-color: transparent;
                transition: .2s all;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
    }
}
