@media(max-width:991px){
    .modals{
        .messagesHeader {
            display: flex;
            div {
                display: flex;
                align-items: center;
                background-color: #1d864a;
                color: #fff;
                height: 40px;
                padding:0 15px;
                justify-content: center;
                flex:1;
                &.active {
                    background-color: #186a3a;
                }
                img {
                    margin-right: 10px;
                }
            }
        }
        .messagesContent {
            // position: relative;
            left: 0;
            transition: .5s all;
            width: 200%;
            // min-height: 100%;
            display: flex;
            background-color: #FFF;
            flex:1;
            position: fixed;
            top: 88px;
            bottom: 0;
            &>div {
                // display: block;
                width: 50%;
                min-height: 100%;
                // height: 100vh;
                flex:1;
                // background-color: #186a3a;
                /* mobile viewport bug fix */
                // min-height: -webkit-fill-available;
            }
            &.active {
                left: -100%;
            }
        }
    }
}